import React,{useEffect,useState} from "react";
import {Helmet} from "react-helmet"
import {Link} from "gatsby"
import logo from "../feed/logo.png"
// import {useMediaQuery} from 'react-responsive'
// import Header from "../components/header"
// import {Link} from "gatsby"

export default () => {

  const [style,setStyle]=useState({});

  useEffect(() => {
    function logoStyle(){
      let s,w,h;
      const {innerWidth: width,innerHeight: height } = window;
      const windowWidth=width;
      const windowHeight=height;
      const logoX=windowWidth/2-225;
      const logoY=windowHeight/4-45;
      if(windowWidth > 991){
        s={top:logoY+"px",left:logoX+"px"}
      }else{
        w = (windowWidth<450)?windowWidth-20:450;
        h = Math.floor(w/5);
        const logoXm=(windowWidth<450)?10:logoX;
        s={top:"45px",left:logoXm+"px",width:w+"px",height:h+"px"};
      }
      setStyle(s);
    }

    window.addEventListener("resize",logoStyle);
    logoStyle();
    return () => {
      window.removeEventListener("resize",logoStyle);
    }
  },[setStyle]);

  return(
    <div>
      <Helmet>
        <style type="text/css">{`
          html, body {
            min-height: 100%;
          }
          body {
              background: url('/bg.jpg') no-repeat center center fixed;
              -webkit-background-size: cover;
              -moz-background-size: cover;
              background-size: cover;
              -o-background-size: cover;
          }

          #footer{font-family: arial,sans-serif;color:rgba(0,0,0,.54);background:#f2f2f2; font-size: 13px; line-height: 40px;}
          #footer .row{border-top:1px solid #e4e4e4;}
          #footer .col-lg-4{cursor: pointer;}

          #logo{position: absolute;width:450px;height:90px;top:-91px;left:0px;}
          #logo img{width:100%;height: 100%;}

          @media (min-width:992px) {
              #more{
                text-align:right;
              }
          }
        `}</style>
        <title>OK!Melbourne</title>
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-160697028-1"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'UA-160697028-1');
          `}
        </script>
      </Helmet>
      <a href="https://accounts.google.com"><button id="signin" type="button" className="m-2 float-right btn btn-outline-dark btn-sm">Log in</button></a>
    	<div id="logo" style={style}><img alt="Logo" src={logo}/></div>
    	<div className="container-fluid fixed-bottom px-0" id="footer">
    		<div className="row">
    			<div className="col-12 ml-2">The Place for Victoria's Best Developers
    			</div>
    		</div>
    		<div className="row justify-content-between">
    			<div className="col-12 col-lg-4 ml-2">
    				Events | Jobs | How to Apply?
    			</div>
    			<div className="col-12 col-lg-4 mx-2" id="more">
    				Feedback | Privacy | Conditions
    			</div>
    		</div>
    	</div>
    </div>
  )
}
